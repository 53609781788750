import Vue from "vue";
import Router from "vue-router";
// import BasePage from "../views/BasePage.vue";

Vue.use(Router);

//下面這段可使 router 指向相同的網址時，不提示錯誤
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  {
    path: "/",
    component: resolve => require(["@/views/BasePage.vue"], resolve),
    children: [
      {
        path: "",
        name: "Home",
        component: resolve => require(["@/views/pages/Home.vue"], resolve)
      },
      {
        path: "privacy",
        name: "Privacy",
        component: resolve => require(["@/views/pages/Privacy.vue"], resolve)
      },
      {
        path: "terms",
        name: "Terms",
        component: resolve => require(["@/views/pages/Terms.vue"], resolve)
      }
    ]
  },
  {
    path: "/404",
    name: "404",
    component: resolve => require(["@/views/pages/Error404.vue"], resolve)
  },
  {
    path: "*", // 此处需特别注意至于最底部
    redirect: "/404"
  }
];

const router = new Router({
  mode: "history",
  routes
});

export default router;
